
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  data() {
    return {
      mail: null,
      name: null,
      message: "",
      sent: false,
    };
  },
  methods: {
    async sendMail() {
      if (this.message.length < 5) {
        alert("Please enter a message.");
        return;
      }

      // send axios to cloud

      const url = "https://send-email-pbe7qvv4bq-lz.a.run.app";

      this.$emit("toggleLoading");

      const response = await axios.post(url, {
        mail: this.mail,
        message: this.message,
        name: this.name,
      });

      this.$emit("toggleLoading");

      this.sent = true;
    },
  },
});
