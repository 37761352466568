
import { defineComponent } from "vue";
import axios from "axios";
import FeedbackForm from "./components/Feedback-Form.vue";

export default defineComponent({
  name: "App",
  components: { FeedbackForm },
  data: function () {
    return {
      isformvalid: false,
      yt_id: "",
      conciseness: "",
      isLoading: false,
      summary: "",
      bullet_points: true,
    };
  },
  methods: {
    navHome() {
      window.open("https://aidvice.co/?ref=yt", "_blank");
    },

    validateForm() {
      console.log("validateForm");

      const form: HTMLFormElement | null = document.getElementById(
        "form"
      ) as HTMLFormElement;
      if (form == null) return;

      let isValid: boolean[] = [];

      const inpt = document.getElementById("link") as HTMLInputElement;
      const inpt_val = inpt.value;

      // is it a valid link?
      if (
        inpt_val.includes("https://www.youtube.com/watch") ||
        inpt_val.includes("https://youtube.com/watch") ||
        inpt_val.includes("https://m.youtube.com/watch")
      ) {
        
        inpt.setCustomValidity("");

        // calcluate youtube id
        let startIndex = inpt_val.indexOf("v=") + 2;
        let start_string = inpt_val.substring(startIndex);

        let endIndex: number | undefined = start_string.indexOf("&");

        
        if (endIndex == -1) endIndex = undefined;

        const yt_id = start_string.substring(0, endIndex);
        this.yt_id = yt_id;
        console.log(this.yt_id);
        isValid.push(true);
      } else if (
        inpt_val.includes("https://youtu.be/") ||
        inpt_val.includes("https://www.youtu.be/")
      ) {
        inpt.setCustomValidity("");
        const yt_id = inpt_val.split("be/")[1];
        this.yt_id = yt_id;
        console.log(yt_id);
        isValid.push(true);
      } else {
        inpt.setCustomValidity("Invalid field.");
        isValid.push(false);
        form.classList.add("was-validated");
      }

      const concisenessElement = document.querySelector(
        'input[name="conciseness"]:checked'
      ) as HTMLInputElement;

      // conciseness check
      if (concisenessElement !== null) {
        const conciseness = concisenessElement.value;
        this.conciseness = conciseness;
        console.log(conciseness);
        (
          document.getElementById("inlineRadio1") as HTMLInputElement
        ).setCustomValidity("");
        (
          document.getElementById("inlineRadio2") as HTMLInputElement
        ).setCustomValidity("");
        (
          document.getElementById("inlineRadio3") as HTMLInputElement
        ).setCustomValidity("");
        isValid.push(true);
      } else {
        (
          document.getElementById("inlineRadio1") as HTMLInputElement
        ).setCustomValidity("Invalid field.");
        (
          document.getElementById("inlineRadio2") as HTMLInputElement
        ).setCustomValidity("Invalid field.");
        (
          document.getElementById("inlineRadio3") as HTMLInputElement
        ).setCustomValidity("Invalid field.");
        isValid.push(false);
        form.classList.add("was-validated");
      }

      if (isValid.some((element) => element === false)) {
        return;
      }

      this.isformvalid = true;
      form.classList.add("was-validated");
    },
    async submitForm() {
      this.validateForm();
      if (!this.isformvalid) {
        alert("make sure you filled out everything correctly!");
        return;
      }

      this.isLoading = true;

      const data = {
        yt_id: this.yt_id,
        conciseness: this.conciseness,
        bullet_points: this.bullet_points,
      };
      console.log(data);

      const response = await axios.post(
        "https://get-yt-summary-pbe7qvv4bq-lz.a.run.app",
        data
      );

      let summary: string = response.data;

      summary.replaceAll("-", "\n -");

      this.summary = response.data;

      this.isLoading = false;

      // axios.post()

      return;
    },
    handleToggle() {
      this.bullet_points = !this.bullet_points;
      return;
    },
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
  },
});
